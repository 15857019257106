<template>
	<detail-page
		v-if="isLoaded"
		:ref="detailPageRefName"
		:formModel="model"
		:formSchema="schema"
		headerTitle="addanewsubpaget"
		tooltipMsg="manualforurlcat3348"
		@saveForm="addUrl($event)"
		@closeDetail="redirectToTable()"
		:showActionDelete="false"
	>
	</detail-page>
</template>

<script>
import DetailPage from '@/components/general/detail-page';
import { detailPageMixin } from '@/components/general/detail-page.mixin';
import http from '../../services/helpers/http';

import { model, fields } from './url-catalog.form-data';

export default {
	components: {
		DetailPage,
	},

	mixins: [detailPageMixin],

	data() {
		return {
			controllerName: 'CatalogUrl',
			routeParam: 'Id',
			model,
			schema: {
				groups: fields,
			},
		};
	},

	methods: {
		loadResources() {
		},

		async addUrl({ closeOnSuccess }) {
			const isValid = await this.$refs[this.detailPageRefName].validate();
			if (!isValid) {
				return;
			}

			// start loading must be after the validation, otherwise the validation will not work
			this.startLoading();

			http.post('CatalogUrl/Insert', `"${this.model.SaveUrlInput}"`)
				.finally(() => this.stopLoading())
				.then(() => {
					closeOnSuccess ? this.redirectToTable() : (this.model.SaveUrlInput = null);
				})
				.catch((err) => {
					// optional chaining is not supported in vue2
					// errorMsg = err?.response?.data?.error || 'Unknown error';
					const errorMsg =
						err && err.response && err.response.data && err.response.data.error ? err.response.data.error : 'Unknown error';
					this.$store.dispatch('alert/error', this.$i18n.t(errorMsg));
				});
		},
	},	
};
</script>