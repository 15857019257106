export const model = {
	SaveUrlInput: null,
};

export const fields = [
	{
		fields: [
			{
				type: 'input',
				inputType: 'url',
				model: 'SaveUrlInput',
				required: true,
				validator: 'string',
				i18n: {
					label: 'subpageurl',
					placeholder: 'urlmuststartwit'
				},
			},
		],
	},
];
